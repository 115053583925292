<template>
  <div
    class="
      border-4 border-dashed border-gray-200
      rounded-lg
      h-96
      flex
      items-center
      justify-center
    "
  >
    <span>nothing here yet, cuddle off.</span>
  </div>
</template>

<script>
export default {
  name: "Users",
  components: {},
}
</script>
